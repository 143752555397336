import swiper from 'swiper';

var worksNav = new swiper ('.js-slider-nav', {
  // Optional parameters
  direction: 'vertical',
  loop: false,
  // slidesPerView: 4,
  speed:1000,
  autoplay: false,
  spaceBetween: 10,
  // centeredSlides: true,
  observer: true,
  // observeParents: true,
  preloadImages: false,
  // noSwiping: true,
  slidesPerView: "auto",
  autoHeight: true,
  allowTouchMove: false,
  // watchSlidesVisibility: true,
  // watchSlidesProgress: true,
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 2
  },
  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar',
  },
  breakpoints: {
    768: {
      direction: 'horizontal',
      allowTouchMove: true,
      spaceBetween: 2,
      slidesPerView: 4
    }
  }
});

var worksMain = new swiper ('.js-slider-main', {
  // Optional parameters
  direction: 'horizontal',
  loop: false,
  effect: "fade",
  // slidesPerView: 5,
  speed:1000,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  spaceBetween: 0,
  centeredSlides: true,
  observer: true,
  // observeParents: true,
  preloadImages: false,
  // noSwiping: true,
  slidesPerView: "auto",
  autoHeight: true,
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 2
  },
  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar',
  },
  breakpoints: {
    768: {
      direction: 'horizontal',
      allowTouchMove: true,
    }
  },
  thumbs: {
    // swiper: worksNav
  },
  on: {
    init: function () {
      $(".js-slider-nav .swiper-slide").eq(this.activeIndex).addClass("is-active");
      // underLine(this.activeIndex);
    },
    slideChange: function () {
      $(".js-slider-nav .swiper-slide").removeClass("is-active");
      $(".js-slider-nav .swiper-slide").eq(this.activeIndex).addClass("is-active");
      // underLine(this.activeIndex);
    },
  }
});

$('.js-slider-nav .swiper-slide').on('click', function(){
  var $this = $(this);
  var index = worksNav.clickedIndex;
  worksMain.slideTo(index);
  // worksNav.slideTo(index);
  // console.log(index);
  // console.log(worksNav.progress);
  // console.log(worksNav.translate);
  // if(index <= 2) {
  //   worksNav.setTranslate(0);
  // } else {
  //   worksNav.slideTo(index);
  // }
  $(".js-slider-nav .swiper-slide").removeClass("is-active");
  $(".js-slider-nav .swiper-slide").eq(index).addClass("is-active");

  // console.log($that.find(".js-slider-nav .swiper-slide").eq(index));

  // $("is-is-active").

})
