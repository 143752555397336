import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';

const scrollmagic = new ScrollMagic.Controller();

var scene_hero = new ScrollMagic.Scene({
	triggerElement: "body",
	triggerHook: "onEnter",
	// duration: 800,
	offset: 0,
})
	// .addIndicators()
	.addTo(scrollmagic);

scene_hero.on("enter", () => {});

const scrollContents = document.querySelectorAll(".--is-scroll");

scrollContents.forEach((e) => {
	let scroll_contents = new ScrollMagic.Scene({
		triggerElement: e,
		triggerHook: "onEnter",
		// duration: 800,
		offset: 0,
	})
		// .addIndicators()
		 .addTo(scrollmagic);

	scroll_contents.on("enter", () => {
		if (e) {
			e.classList.add("active");
		}
	});
});