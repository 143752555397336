var agent = navigator.userAgent;
if(agent.search("iPhone") != -1){
  $('body').addClass('ua-iphone');
}

if(agent.search("iPad") != -1){
  $('body').addClass('ua-ipad');
}

if(agent.search("Android") != -1){
  $('body').addClass('ua-android');
}


//pc判定
const devices = ["iPhone", "iPad", "Android"];
var device = devices.filter(function(v) {
  if(agent.search(v) > 0) {
    return v
  }
});
if(device.length == 0){
  $('body').addClass('ua-pc');
}
