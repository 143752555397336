import mixitup from 'mixitup';
import {TweenMax, Power2, TimelineLite} from "gsap/TweenMax";

var tl = new TimelineMax();

if($('.results-items').length) {
  // var pair=location.search.substring(1).split('&');
  var arg = new Object;
  var pair=location.search.substring(1).split('&');
  for(var i=0;pair[i];i++) {
    var kv = pair[i].split('=');
    arg[kv[0]]=kv[1];
  }

  if(arg.category) {
    console.log("."+arg.category);
    var mixer = mixitup('.results-items', {
      load: {
          filter: "."+arg.category
      }
    });
    tl.to(".results-items__unit", 1.2,{ alpha: 1, ease:Power1.easeOut, delay: 0, onComplete: function(){
      $('.results-items__unit').addClass('is-active');
    }})
  } else {
    var mixer = mixitup('.results-items', {
      load: {
          filter: 'all'
      }
    });
    tl.to(".results-items__unit", 1.2,{ alpha: 1, ease:Power1.easeOut, delay: 0, onComplete: function(){
      $('.results-items__unit').addClass('is-active');
    }})
  }
}

// mixer.filter('.construction');
