import smoothScroll from '../../plugins/smooth-scroll.js';

//アンカーリンクの場合data-scrollをつける
$("a[href*='#']").each(function(){
  var href = $(this).attr('href');
  if(href && href !== "#") {
    var id = href.substring(href.indexOf("#"),href.length);
    if(($(id).length)){
      // console.log(href)
      $(this).attr('data-scroll', '');
    }
  }
});
if(!$('.is-intorduction').length) {
  //data-scrollがついているものはスムーススクロール
  smoothScroll.init({
    speed: 500,
    offset: $(window).width() >= 768 ? 74 : 50
  });
} else {
  //data-scrollがついているものはスムーススクロール
  smoothScroll.init({
    speed: 500,
    offset: $(window).width() >= 768 ? 24 : 50
  });
}
