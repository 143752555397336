import swiper from "swiper";

const sepecialContent = new swiper(".js-special-content-slider", {
  // Optional parameters
  direction: "horizontal",
  loop: true,
  // effect: "fade",
  slidesPerView: 'auto',
  speed: 1000,
  spaceBetween: 40,
  centeredSlides: true,
  observer: true,
  observeParents: true,
  preloadImages: true,
  // noSwiping: true,
  autoHeight: true,
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 2,
  },
  navigation: {
    nextEl: ".special-content-swiper-button-next",
    prevEl: ".special-content-swiper-button-prev",
  },
  breakpoints: {
    768: {
      direction: "horizontal",
      allowTouchMove: true,
      centeredSlides: true,
      slidesPerView: 'auto',
      spaceBetween: 20,
    },
  },
});
