document.addEventListener('wpcf7mailsent', function(event) {
  location = '/finish.php';
}, false);

const back = document.querySelector(".wpcf7c-btn-back");
if(back) {
    back.addEventListener("click", () => {
      document.querySelector(".wpcf7-form").classList.remove("failed");
    });
}

  $(function () {

    $('.wpcf7-submit').on('wpcf7:mailsent', function () {
      location = "/finish.php";
    });
    $('.wpcf7c-btn-back').on('click', function () {
      $("#input-label").show();
      $("#confirm-label").hide();
      $("input[type=radio]:not(:checked)").closest('span').show();
      $("input[type=checkbox]:not(:checked)").closest('span').show();
      $(".txt_comment").show();
      $("body").removeClass("confirm");
    });

    $('.wpcf7-list-item-label').each(function () {
      var text = $(this).html();
      $(this).html(text.replace('&lt;br&gt;', '<br>'));
    });
  });