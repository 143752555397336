import swiper from 'swiper';

var worksMain = new swiper ('.js-result-slider', {
  // Optional parameters
  direction: 'horizontal',
  loop: true,
  // effect: "fade",
  slidesPerView: 'auto',
  speed:1000,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  spaceBetween: 40,
  // centeredSlides: true,
  observer: true,
  observeParents: true,
  preloadImages: true,
  // noSwiping: true,
  autoHeight: true,
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 2
  },
  navigation: {
      nextEl: '.top-results__unit-button-next',
      prevEl: '.top-results__unit-button-prev',
  },
  breakpoints: {
    768: {
      direction: 'horizontal',
      allowTouchMove: true,
      centeredSlides: true,
      slidesPerView: 2,
      spaceBetween: 20
    }
  },
});
