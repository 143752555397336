import {TweenMax, Power2, TimelineLite} from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';
import isMobile from '../common/is-mobile.js';


var controller = new ScrollMagic.Controller();
var tl = new TimelineMax();

if($('.recruit-interview').length) {

  var scene_interview1 = new ScrollMagic.Scene({
    triggerElement: ".recruit-interview__header",
    triggerHook: 'onCenter',
    // duration: 100,
    offset: -100
  })
  // .setClassToggle("#aaaa", "is-current")
  // .addIndicators()
  .addTo(controller);

  scene_interview1.on('enter', ()=>{
    tl
    .to(".recruit-interview__header-text", 0.5,{ y: "0", alpha: 1, delay: 0.3 })
  });

  $('.recruit-interview__faqItem').each( function(index) {
    $(this).addClass('number' + index);
    let that = $(this);

    if($(this).hasClass('is-col--2')) {
      if(!isMobile(768)){
        var scene_interview2 = new ScrollMagic.Scene({
          triggerElement: ".number" + index,
          triggerHook: 'onCenter',
          // duration: 100,
          offset: -100
        })
        // .setClassToggle("#aaaa", "is-current")
        // .addIndicators()
        .addTo(controller);

        scene_interview2.on('enter', ()=>{
          tl.to($(this).find('h4'), 0.5,{ y: "0", alpha: 1})
          .to($(this).find('p'), 0.5,{ y: "0", alpha: 1})
          $(this).addClass('is-current');
        });
      } else {
        var scene_interview2 = new ScrollMagic.Scene({
          triggerElement: ".number" + index,
          triggerHook: 'onCenter',
          // duration: 100,
          offset: -100
        })
        // .setClassToggle("#aaaa", "is-current")
        // .addIndicators()
        .addTo(controller);

        scene_interview2.on('enter', ()=>{
          tl.to($(this).find('h4'), 0.5,{ y: "0", alpha: 1})
          .to($(this).find('p'), 0.5,{ y: "0", alpha: 1, onComplete: function(){
            that.addClass('is-current');
          } })
        });
      }
    } else {
      var scene_interview3 = new ScrollMagic.Scene({
        triggerElement: ".number" + index,
        triggerHook: 'onCenter',
        // duration: 100,
        offset: -70
      })
      // .setClassToggle("#aaaa", "is-current")
      // .addIndicators()
      .addTo(controller);

      scene_interview3.on('enter', ()=>{
        tl.to($(this).find('h4'), 0.5,{ y: "0", alpha: 1})
        .to($(this).find('p'), 0.5,{ y: "0", alpha: 1, onComplete: function(){
          that.addClass('is-current');
        } })
      });
    }
  })
}
