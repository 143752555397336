var pathname = function () {
  return location.pathname == '/index.php' ? '/' : location.pathname
}

$('.l-header__navItem').each(function(){
  var $href = $(this).find('a').attr('href');
  // console.log($href);
  if(location.href.match($href) && $href != "/") {
    $(this).addClass('is-current');
  }
  if(pathname() == '/') {
    $('.l-header__nav .l-header__navItem').eq(0).addClass('is-current');
  }
});
