import {TweenMax, Power2, TimelineLite} from "gsap/TweenMax";

//右下のページトップへ戻る
$(window).on('scroll', function(){
  if(150 <= $(window).scrollTop()) {
    // $(".c-scrollToPageTop").addClass("is-active");
    TweenMax.to(".c-scrollPageTop", 0.3, { alpha: 1, y: '0%'});
  } else {
    // $(".c-scrollToPageTop").removeClass("is-active");
    TweenMax.to(".c-scrollPageTop", 0.3, { alpha: 0, y: '70%'});
  }
});
