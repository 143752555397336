import {TweenMax, Power2, TimelineLite} from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';

var controller = new ScrollMagic.Controller();

if($('.top-first-contents').length) {


  var fixed_nav = new ScrollMagic.Scene({
    triggerElement: ".top-first-contents" ,
    triggerHook: 'onLeave',
    // duration: 1000,
    offset :500
  })
  // .addIndicators()
  .addTo(controller);

  fixed_nav.on('enter', ()=>{
    //triggerElementを過ぎたとき
    $('#l-header').addClass('is-scrolled');
  });
  fixed_nav.on('leave', ()=>{
    //triggerElementを抜けたとき
    $('#l-header').removeClass('is-scrolled');
  });
}
