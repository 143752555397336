import InfiniteScroll from 'infinite-scroll';

if($('.topics-page').length) {

var infScroll = new InfiniteScroll( '#topics__list', { //大枠のセレクタ
  append: '.topics__listItem', //読み込むボックスたちのセレクタ
  path: '.next_posts_link a', //次ページへ飛ぶための「次へ」ボタンのセレクタ
  hideNav: '.next_posts_link', //ページネーションのセレクタ
  button: '.c-infinite-button', //「もっと見る」ボタンのセレクタ
  scrollThreshold: false, //自動で次のページを読み込まないようにする
  status: '.scroller-status', // ステータスのセレクタ
  history: true, //読み込み後のURLを変更しない
  checkLastPage: true,
  loading : {
    img : "loading.gif",
    finishedMsg : "すべての読み込みが完了しました"
  },
});

}
