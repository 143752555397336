// import $ from 'jquery';
import svg4everybody from 'svg4everybody';
import objectFitImages from 'object-fit-images';
import flexibility from 'flexibility';
import mixitup from 'mixitup';
import InfiniteScroll from 'infinite-scroll';
import {TweenMax, Power2, TimelineLite} from "gsap/TweenMax";
import isMobile from './modules/common/is-mobile.js';
import './modules/common/smoothScroll.js';
import './modules/common/lazyload.js';
import './modules/common/userAgent.js';
import './modules/common/sp-menu.js';//スマホメニュー
import './modules/common/megamenu.js';//メガメニュー
import './modules/common/highlight.js';
import './modules/common/scroll.js';
import './modules/common/scrollPageTop.js';
import './modules/common/header-current-nav.js';
import './modules/top/top-main-swiper.js';
import './modules/top/top-results-swiper.js';
import './modules/top/animation.js';
import './modules/results/works-swiper.js';
import './modules/results/category-filter.js';
import './modules/business/side-nav.js';
import './modules/topics/infinitescroll.js';
import './modules/recruit/animation.js';
import './modules/recruit/page-jump.js';
import './modules/specialcontent/special-content-career-swiper.js';
import './modules/specialcontent/scroll.js';
import './modules/common/form.js';



flexibility(document.documentElement);//flexboxのie対応
objectFitImages();//object-fitのie対応
svg4everybody();//svgをieで表示

//電話番号スマホのみ掛ける
$("a[href*='tel']").on("click", function(e){
  if(!isMobile(768)){
    return false
  }
});
