import swiper from 'swiper';
import * as ProgressBar from "progressbar.js";


var autoplay = 5000;
// var width = 1;

var heroMain = new swiper ('.js-hero-slider', {
  // Optional parameters
  direction: 'horizontal',
  loop: true,
  effect: "fade",
  speed:1000,
  autoplay: {
    delay: autoplay,
    disableOnInteraction: false,
  },
  observer: true,
  preloadImages: true,
  autoHeight: true,
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 2
  },
  watchSlidesProgress: true,
  on: {
    slideChange: move
  }
});
function move() {
  var bar = new ProgressBar.Line(progress, {
    strokeWidth: 4,
    easing: 'easeInOut',
    duration: 5000,
    color: '#305CA1',
    trailColor: '#B0BFDD',
    trailWidth: '100%',
    svgStyle: {width: '100%', height: '100%'}
  });
  bar.animate(1.0);  // Number from 0.0 to 1.0
}



// console.log(swiper)
