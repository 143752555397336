import {TweenMax, Power2, TimelineLite} from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';
import isMobile from '../common/is-mobile.js';



var controller = new ScrollMagic.Controller();
var tl = new TimelineMax();

if($('.top-page').length) {

  $(window).on('load', function() {
    tl.to('.top-first-contents__main', 0.8, { autoAlpha: 1 });
  });

  var scene_about1 = new ScrollMagic.Scene({
    triggerElement: ".top-business",
    triggerHook: 'onCenter',
    // duration: 100,
    offset: -350
  })
  // .setClassToggle("#aaaa", "is-current")
  // .addIndicators()
  .addTo(controller);

  scene_about1.on('enter', ()=>{
    tl
    .to(".top-business .top-title__h2 h2", 0.4,{ y: "0", alpha: 1 })
    .set('.top-business .top-title__h2', {className:"+=is-active"})
    .to(".top-business .top-title__h2 span", 0.3,{ y: "0", alpha: 1, delay: 0.5 })
  });


  $('.top-business__typeInner').each( function(index) {
    $(this).addClass('number' + index);
    let that = $(this);

    var scene_about2 = new ScrollMagic.Scene({
      triggerElement: ".number" + index,
      triggerHook: 'onCenter',
      // duration: 100,
      offset: -250
    })
    // .setClassToggle("#aaaa", "is-current")
    // .addIndicators()
    .addTo(controller);

    scene_about2.on('enter', ()=>{
      tl
      .to($(that), 0.5,{ y: "0", alpha: 1 })
    });

  })

  //会社概要
  var scene_company1 = new ScrollMagic.Scene({
    triggerElement: ".top-company",
    triggerHook: 'onCenter',
    // duration: 100,
    offset: -150
  })
  // .setClassToggle("#aaaa", "is-current")
  // .addIndicators()
  .addTo(controller);

  scene_company1.on('enter', ()=>{
    tl
    .to(".top-company .top-title__h2 h2", 0.4,{ y: "0", alpha: 1 })
    .set('.top-company .top-title__h2', {className:"+=is-active"})
    .to(".top-company .top-title__h2 span", 0.3,{ y: "0", alpha: 1, delay: 0.5 })
  });


  //施工実績
  var scene_results1 = new ScrollMagic.Scene({
    triggerElement: ".top-results",
    triggerHook: 'onCenter',
    // duration: 100,
    offset: -100
  })
  // .setClassToggle("#aaaa", "is-current")
  // .addIndicators()
  .addTo(controller);

  scene_results1.on('enter', ()=>{
    tl
    .to(".top-results .top-title__h2 h2", 0.4,{ y: "0", alpha: 1 })
    .set('.top-results .top-title__h2', {className:"+=is-active"})
    .to(".top-results .top-title__h2 span", 0.3,{ y: "0", alpha: 1, delay: 0.3 })
    .to(".top-results__image", 0.5,{ alpha: 1 })
  });

  //お知らせ
if(!isMobile(768)){
  var scene_topics1 = new ScrollMagic.Scene({
    triggerElement: ".top-news",
    triggerHook: 0.9,
    offset: 0
  })
  .addTo(controller);

  scene_topics1.on('enter', ()=>{
    // 新しいタイムラインを作成
    const newsTimeline = new TimelineMax();
    newsTimeline
      .to(".top-news__main-title", 0.3, { y: "0", alpha: 1 })
      .to(".top-news__bgItem", 0.3, { x: "0" }, "-=0.1")
      .to(".top-news__list", 0.3, { y: "0", alpha: 1 }, "-=0.1");
  });

} else {
  var scene_topics2 = new ScrollMagic.Scene({
    triggerElement: ".top-news",
    triggerHook: 0.9,
    offset: 0
  })
  .addTo(controller);

  scene_topics2.on('enter', ()=>{
    // 新しいタイムラインを作成
    const newsTimeline = new TimelineMax();
    newsTimeline
      .to(".top-news__main-title", 0.3, { y: "0", alpha: 1 })
      .to(".top-news__list", 0.3, { y: "0", alpha: 1 }, "-=0.1");
  });
}


  // //お知らせ
  // if(!isMobile(768)){
  //   var scene_topics1 = new ScrollMagic.Scene({
  //     triggerElement: ".top-news",
  //     triggerHook: 'onCenter',
  //     // duration: 100,
  //     offset: -150
  //   })
  //   // .setClassToggle("#aaaa", "is-current")
  //   // .addIndicators()
  //   .addTo(controller);

  //   scene_topics1.on('enter', ()=>{
  //     tl.to(".top-news__main-title", 0.5,{ y: "0", alpha: 1, onComplete: function(){
  //       tl.to(".top-news__bgItem", 0.5,{ x: "0", onComplete: function(){
  //         tl.to(".top-news__list", 0.5,{ y: "0", alpha: 1, delay: 0.2 })
  //       } })
  //     } })
  //   });

  // } else {
  //   var scene_topics2 = new ScrollMagic.Scene({
  //     triggerElement: ".top-news",
  //     triggerHook: 'onCenter',
  //     // duration: 100,
  //     offset: -200
  //   })
  //   // .setClassToggle("#aaaa", "is-current")
  //   // .addIndicators()
  //   .addTo(controller);

  //   scene_topics2.on('enter', ()=>{
  //     tl.to(".top-news__main-title", 0.5,{ y: "0", alpha: 1})
  //     tl.to(".top-news__list", 0.7,{ y: "0", alpha: 1 })
  //   });

  // }
  //採用情報
  if(!isMobile(768)){
    var scene_recruit1 = new ScrollMagic.Scene({
      triggerElement: ".top-recruit",
      triggerHook: 'onCenter',
      // duration: 100,
      offset: 100
    })
    // .setClassToggle("#aaaa", "is-current")
    // .addIndicators()
    .addTo(controller);
  } else {
    var scene_recruit1 = new ScrollMagic.Scene({
      triggerElement: ".top-recruit",
      triggerHook: 'onCenter',
      // duration: 100,
      offset: -50
    })
    // .setClassToggle("#aaaa", "is-current")
    // .addIndicators()
    .addTo(controller);
  }


  scene_recruit1.on('enter', ()=>{
    tl
    .to(".top-recruit__bgItem", 0.4,{ y: "0", alpha: 1 })
    .to(".top-recruit .top-title__h2 h2", 0.4,{ y: "0", alpha: 1 })
    .set('.top-recruit .top-title__h2', {className:"+=is-active"})
    .to(".top-recruit .top-title__h2 span", 0.3,{ y: "0", alpha: 1, delay: 0.5 })
  });

}
