var arg = new Object;
var path = location.pathname.split('/').filter(e => Boolean(e));
var pathname = path[path.length - 1]
// for(var i=0;path[i];i++) {
//   var kv = path[i].split('=');
//   arg[kv[0]]=kv[1];
// }


if($('.business__page-sub').length) {
  $('.business__functionItem').each(function() {
    var $href = $(this).find('a').attr('href').split('/').filter(e => Boolean(e));
    var $href = $href[$href.length - 1];
    if(pathname.match($href)) {
      $(this).closest('.business__functionItem').addClass('is-current');
    }
  })
}
